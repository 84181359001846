import React from 'react';

const CustomInput = ({ label, value, onChange, placeholder, type = 'text', error, disabled }) => {
  const inputStyle = {
    background: '#FFFFFF',
    border: `2px solid ${error ? 'red' : '#5A5A5A'}`,
    borderRadius: '43px',
    padding: '10px',
    width: '100%',
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.6 : 1,
    fontFamily: '"Quicksand", sans-serif',
    color:"#5A5A5A",
    fontSize:"20px",
    paddingLeft:"20px",
  
  };

  const cotainerStyle = {

    marginBottom:"30px"

  };

  const errorStyle = {
    textAlign: 'left',
    color: '#901941',
    color: '#ffffff',
    fontFamily: '"Quicksand", sans-serif',
    fontSize:"16px",
    fontWeight:"500",
    paddingLeft:"20px",

  };

  return (
    <div style={cotainerStyle}>
      <label className='labelStyle'>{label}</label>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        style={inputStyle}
        disabled={disabled}
      />
      {error && <div style={errorStyle}>{error}</div>}
    </div>
  );
};

export default CustomInput;
