import React, { useEffect, useState } from "react";
import CustomInput from "../component/CoustomInput";
import HeaderComponent from "../component/HeaderComponent";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Select from "react-select";
import VisitorsPicture from "./VisitorsPicture";
import {
  proposeofvisit,
  bookspaces,
  numberofvisiters,
  numberofseats,
} from "../component/constant";
import VisitorEndpoint from "../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import {postFormData, postJsonData} from "../Services/AxoisInterceptorr";
import images from "../assets/images/letsbrimlogo.png";
import imageCompression from 'browser-image-compression';




const VisitorsDetails = () => {
  const [visitorName, setVisitorName] = useState("");
  const [visitorMobile, setVisitorMobile] = useState("");
  const [visitorEmail, setVisitorEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [personToMeet, setPersonToMeet] = useState("");
  const [startDate, setStartDate] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [visitorNameError, setVisitorNameError] = useState(null);
  const [visitorEmailError, setVisitorEmailError] = useState(null);
  const [visitorMobileError, setVisitorMobileError] = useState(null);
  const [personToMeetError, setPersonToMeetError] = useState(null);
  const [selectedNumVisitorsError, setSelectedNumVisitorsError] =
    useState(null);
  const [selectedPurposeError, setSelectedPurposeError] = useState(null);
  const [selectedBookSpaceError, setSelectedBookSpaceError] = useState(null);
  const [selectedNumSeatsError, setSelectedNumSeatsError] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  const [selectedOtherField, setSelectedOtherField] = useState(null);
  const [selectedOtherFieldError, setSelectedOtherFieldError] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(null);
  const [formSubmissionError, setFormSubmissionError] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false); 
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [selectedBookSpace, setSelectedBookSpace] = useState(null);
  const [selectedNumVisitors, setSelectedNumVisitors] = useState(null);
  const [selectedNumSeats, setSelectedNumSeats] = useState(null);


  const handleNumVisitorsChange = (selectedOption) => {
    setSelectedNumVisitors(selectedOption);

    // Perform validation
    if (!selectedOption) {
      setSelectedNumVisitorsError("Number of visitors is required");
    } else {
      setSelectedNumVisitorsError(null);
    }
  };
  const handleImageCapture = async (imageData) => {
    try {
      const compressedImage = await compressImage(imageData);
      setCapturedImage(compressedImage);
    } catch (error) {
      // console.error('Error compressing image:', error);
    }
  };
  
  
  const compressImage = async (imageSrc) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
  
    try {
      const compressedFile = await imageCompression(imageSrc, options);
      return compressedFile;
    } catch (error) {
      // console.error('Error compressing image:', error);
      throw error; 
    }
  };
  
  
  const handleStartDateChange = (newValue) => {
    const selectedDate = new Date(newValue);
    const today = new Date();
  
    if (selectedDate >= today) {
      setStartDateError(null); 
      setStartDate(newValue);
    } else {
      setStartDateError("Select today or a future date");
    }
  };
  
  const handlePurposeChange = (selectedOption) => {

    setSelectedPurpose(selectedOption);
    setSelectedBookSpace(null);
    setSelectedNumSeats(null);

    // Perform validation
    if (!selectedOption) {
      setSelectedPurposeError("Purpose of visit is required");
    } else {
      setSelectedPurposeError(null);
    }
  };

  const handleBookSpaceChange = (selectedOption) => {
    setSelectedBookSpace(selectedOption);

    // Perform validation
    if (!selectedOption || selectedOption.length === 0) {
      setSelectedBookSpaceError("Select at least one space");
    } else {
      setSelectedBookSpaceError(null);
    }
  };

  const handleNumSeatsChange = (selectedOption) => {
    setSelectedNumSeats(selectedOption);

    // Perform validation
    if (!selectedOption) {
      setSelectedNumSeatsError("Number of seats is required");
    } else {
      setSelectedNumSeatsError(null);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleProposeSelection = () => {
    setShowOptions(true);
  };




  const handleMobileInputChange = (event) => {
    const newValue = event.target.value;
    setVisitorMobile(newValue);

    // Perform validation using regex
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(newValue)) {
      setVisitorMobileError("Enter a valid phone number");
    } else {
      setVisitorMobileError(null);
    }
  };

  const validateInput = () => {
    let isValid = true;
    if (!visitorName.trim()) {
      setVisitorNameError("Name is required");
      return false;
    } else {
      setVisitorNameError(null);
    }
    // Validation for visitorMobile using regex

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(visitorMobile)) {
      setVisitorMobileError("Enter a valid phone number");
      isValid = false;
    } else {
      setVisitorMobileError(null);
    }

    // Validation for visitorEmail using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(visitorEmail)) {
      setVisitorEmailError("Enter a valid email address");
      isValid = false;
    } else {
      setVisitorEmailError(null);
    }

    // Validation for companyName
    if (!companyName.trim()) {
      setCompanyNameError("Company Name is required");
      isValid = false;
    } else {
      setCompanyNameError(null);
    }
    // Validation for "Person(s) you would like to meet"
    if (!personToMeet.trim()) {
      setPersonToMeetError("Person(s) to meet is required");
      return false;
    } else {
      setPersonToMeetError(null);
    }

    // Check for errors in the specific fields
    if (!selectedNumVisitors) {
      setSelectedNumVisitorsError("Number of visitors is required");
      return false;
    } else {
      setSelectedNumVisitorsError(null);
    }

    if (!selectedPurpose) {
      setSelectedPurposeError("Purpose of visit is required");
      return false;
    } else {
      setSelectedPurposeError(null);
    }

    if (selectedPurpose) {
      if (selectedPurpose.value === "Book Spaces") {
        if (!selectedBookSpace || selectedBookSpace.length === 0) {
          setSelectedBookSpaceError("Select at least one space");
          isValid = false;
        } else {
          setSelectedBookSpaceError(null);
        }

        // Validate selected number of seats
        if (!selectedNumSeats) {
          setSelectedNumSeatsError("Number of seats is required");
          isValid = false;
        } else {
          setSelectedNumSeatsError(null);
        }

        // Validate start date
        if (!startDate) {
          setStartDateError("Start date is required");
          isValid = false;
        } else {
          setStartDateError(null);
        }
      } else if (selectedPurpose.value === "Other") {
        // Validate selected other field
        if (!selectedOtherField) {
          setSelectedOtherFieldError("Other field is required");
          isValid = false;
        } else {
          setSelectedOtherFieldError(null);
        }
      }
    }

    return true;
  };

  const handleInputChange = (
    newValue,
    validationFunction,
    errorSetter,
    fieldName
  ) => {

    validationFunction(newValue);

    // Perform validation
    switch (fieldName) {
      case "visitorEmail":
        errorSetter(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newValue)
            ? null
            : "Enter a valid email address"
        );
        break;
      case "companyName":
        // Validation for companyName
        if (!newValue.trim()) {
          errorSetter("Company Name is required");
        } else {
          errorSetter(null);
        }
        break;

      case "personToMeet":
        errorSetter(newValue.trim() ? null : "Person(s) to meet is required");
        break;
      case "startDate":
        validationFunction(newValue);
        errorSetter(newValue.trim() ? null : "Start date is required");
        break;
      default:
        errorSetter(newValue.trim() ? null : `${fieldName} is required`);
    }
  };
  const handleOtherFieldChange = (newValue) => {
    setSelectedOtherField(newValue);

    setSelectedOtherFieldError(
      newValue.trim() ? null : "Other field is required"
    );
  };

  
 
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Validate all fields
    const isFormValid = validateInput();
    if (!otpVerified) {
      toast.error("Please verify your phone number first.");
      return;
    }
    if (!capturedImage) {
      toast.error("Please capture a visitor's picture.");
      return;
    }
  

    if (isFormValid) {
      
      const formData = {
        name:visitorName,
        mobileNumber:visitorMobile,
        email:visitorEmail,
        companyName:companyName,
        personToMeet:personToMeet,
        numberOfVisitors:selectedNumVisitors.value,
        purposeOfVisit:selectedPurpose.value,
      };
      if (selectedPurpose.value === "Book Spaces") {
        const selectedSpaceValues = selectedBookSpace.map(space => space.value);

        formData.selectSpace = selectedSpaceValues;
        formData.numberOfSeats = selectedNumSeats.value;
        formData.startDate = startDate;
      }
      if (selectedPurpose.value === "Other") {
        formData.otherNote = selectedOtherField;
      
      }
       const formDataNew = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataNew.append(key, formData[key]);
      });
      formDataNew.append('profileImage', capturedImage);
      

      postFormData(
        VisitorEndpoint.CREATE_VISITORS,
        formDataNew,
        () => {},
        (res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            toast.success(res.data.message);
           
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        },
        (error) => {
          toast.error(error.response.data.message);

         
        }
      );
      setFormSubmissionError(null);
    
    } else {
      setFormSubmissionError("Please correct the errors in the form.");

    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "2px solid #5A5A5A",
      borderRadius: "43px",
      backgroundColor: "#FFFFFF",
      paddingLeft: "20px",
      color: "#5A5A5A",
      fontFamily: "Quicksand, sans-serif",
      fontWeight: 500,
      fontSize: "25px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#5A5A5A" : "#FFFFFF",
      color: state.isSelected ? "#FFFFFF" : "#5A5A5A",
      fontFamily: "Quicksand, sans-serif",
      fontSize: "16px",
    }),
  };
  const handleRequestOtp = () => {

    if (!visitorMobile) {
      toast.error("Please enter your phone number first.");
      return;
    }
  
    const phoneNumberWithCountryCode = "91" + visitorMobile;
    const jsonData = {
      mobileNumber: +phoneNumberWithCountryCode,
    };

    postJsonData(VisitorEndpoint.SEND_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setOtp("");
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );
  
    setShowOtpInput(true);
    setPhoneNumberDisabled(true);
  };
  const handleResendtOtp = () => {
    const phoneNumberWithCountryCode = "91" + visitorMobile;
    const jsonData = {
      mobileNumber: +phoneNumberWithCountryCode,
    };

    postJsonData(
      VisitorEndpoint.RESEND_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setOtp("");
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

    setShowOtpInput(true);
    setPhoneNumberDisabled(true);
  };

  const handleEditPhoneNumber = () => {
    setShowOtpInput(false);
    setPhoneNumberDisabled(false);

  };
  const handleOtpSubmit = (e) => {
    e.preventDefault();
        const phoneNumberWithCountryCode = "91" + visitorMobile;
    const jsonData = {
      mobileNumber: parseInt(phoneNumberWithCountryCode),
      otp: parseInt(otp),
    };

    postJsonData(
      VisitorEndpoint.VERIFY_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setShowOtpInput(false);
          setPhoneNumberDisabled(true);
          setOtpVerified(true);
        }
      },
      (error) => {
        toast.error(error.response.data.message);
        setShowOtpInput(true);
        setOtp("");
      }
    );

  };

  return (
    <>
      <div className="container_fluid">
        <HeaderComponent />
        <div className="container">
          <form onSubmit={handleFormSubmit}>
            <div className="Home_content">
              <div className="form_content">
                <CustomInput
                  label="Name"
                  value={visitorName}
                  onChange={(newValue) =>
                    handleInputChange(
                      newValue,
                      setVisitorName,
                      setVisitorNameError,
                      "visitorName"
                    )
                  }
                  placeholder="Enter your name"
                  error={visitorNameError}
                />

                <div className="mobile_function">
                  <p>Mobile</p>
                  {showOtpInput ? (
                    <div className="align_self" onClick={handleEditPhoneNumber}>
                      <h5>Edit Phone Number</h5>
                    </div>
                  ) : (
                    <div className="align_self">
                        {otpVerified ? (
                          <h6>Verified</h6>
                        ) : (
                          <div onClick={handleRequestOtp}>
                            <h5>Request OTP</h5>
                          </div>
                        )}
                      </div>
                  )}
                </div>
                <input
                  type="number"
                  placeholder="Enter your phone number"
                  className={`inputstyle ${
                    phoneNumberDisabled ? "disabled" : ""
                  }`}
                  disabled={phoneNumberDisabled}
                  value={visitorMobile}
                  onChange={handleMobileInputChange}
                />

                {visitorMobileError && (
                  <span className="error-message">{visitorMobileError}</span>
                )}

                {showOtpInput && (
                  <>
                    {" "}
                    <div className="otp-container">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        inputStyle="otp-input"
                        renderSeparator={
                          <span className="otp-separator">-</span>
                        }
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="submit_content">
                      <p onClick={handleResendtOtp}>Resend OTP</p>
                      <div className="submi_content_button">
                        <button type="button" onClick={handleOtpSubmit}>Submit</button>
                      </div>
                    </div>
                  </>
                )}
                <div className="margin_adjust">
                  <CustomInput
                    label="Email"
                    value={visitorEmail}
                    onChange={(newValue) =>
                      handleInputChange(
                        newValue,
                        setVisitorEmail,
                        setVisitorEmailError,
                        "visitorEmail"
                      )
                    }
                    type="email"
                    placeholder="example@gmail.com"
                    error={visitorEmailError}
                  />
                </div>
                <CustomInput
                  label="Company Name"
                  value={companyName}
                  onChange={(newValue) =>
                    handleInputChange(
                      newValue,
                      setCompanyName,
                      setCompanyNameError,
                      "companyName"
                    )
                  }
                  placeholder="XYZ Pvt. Ltd."
                  error={companyNameError}
                />

                <CustomInput
                  label="Person(s) you would like to meet"
                  value={personToMeet}
                  onChange={(newValue) =>
                    handleInputChange(
                      newValue,
                      setPersonToMeet,
                      setPersonToMeetError,
                      "personToMeet"
                    )
                  }
                  placeholder="Person's name"
                  error={personToMeetError}
                />
                <div className="react_function">
                  <p>Number of Visitors</p>
                  <Select
                    defaultValue={""}
                    name="Number of Visitors"
                    options={numberofvisiters}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={customStyles}
                    value={selectedNumVisitors}
                    onChange={handleNumVisitorsChange}
                  />
                  {selectedNumVisitorsError && (
                    <span className="error-message">{selectedNumVisitorsError}</span>
                  )}
                </div>

                <div className="reactvisit_function">
                  <p>Purpose of Visit</p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedPurpose}
                    onChange={handlePurposeChange}
                    name="color"
                    options={proposeofvisit}
                    styles={customStyles}
                  />
                  {selectedPurposeError && (
                    <span className="error-message">{selectedPurposeError}</span>
                  )}
                </div>

                {selectedPurpose && selectedPurpose.value === "Book Spaces" && (
                  <>
                    <div className="react_function">
                      <p>Select Space</p>
                      <Select
                        defaultValue={null}
                        isMulti
                        name="Bookspaces"
                        options={bookspaces}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={customStyles}
                        value={selectedBookSpace}
                        onChange={handleBookSpaceChange}
                      />
                      {selectedBookSpaceError && (
                        <span className="error-message">
                          {selectedBookSpaceError}
                        </span>
                      )}
                    </div>

                    <div className="react_function">
                      <p>Number of Seats</p>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={null}
                        name="Number of Seats"
                        options={numberofseats}
                        styles={customStyles}
                        value={selectedNumSeats}
                        onChange={handleNumSeatsChange}
                      />
                      {selectedNumSeatsError && (
                        <span className="error-message">{selectedNumSeatsError}</span>
                      )}
                    </div>
                    <CustomInput
                      label="Start date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      type="date"
                      placeholder="Select date"
                      error={startDateError}
                    />
                  </>
                )}
                {selectedPurpose && selectedPurpose.value === "Other" && (
                  <CustomInput
                    label="Other"
                    value={selectedOtherField}
                    onChange={(newValue) => handleOtherFieldChange(newValue)}
                    placeholder="Enter details"
                    error={selectedOtherFieldError}
                  />
                )}
              </div>
           
              <div className="camara_container">
                <VisitorsPicture onImageCapture={handleImageCapture} />
              </div>
            </div>
  {formSubmissionError && (
          <div style={{marginBottom:"10px"}} className="error-message">{formSubmissionError}</div>
        )}

            <div className="footer_button_container">
              <button type="submit" className="footer_button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VisitorsDetails;
