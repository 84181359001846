export const BASE_URL = process.env.REACT_APP_BASE_URL;

const VisitorEndpoint = {
  CREATE_VISITORS: "api/v1/visitor",
  SEND_OTP: "api/v1/visitor/sendotp",
  VERIFY_OTP: "api/v1/visitor/verifyotp",
  RESEND_OTP: "api/v1/visitor/resendotp",
 

 

};

export default VisitorEndpoint;
