import React from "react";
import Logo from "../assets/images/letsbrimlogo.png";
const HeaderComponent = () => {
  return (
    <>
      <div className="header_container_fluid">
        <div className="container">
          <div className="header_content">
          <div className="header_images">
            <img src={Logo} alt="logo" />
            </div>
            {/* <h1>Visitor's Details</h1> */}
            <h1>Visitor's Details</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
