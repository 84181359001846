import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import "./style.css";
import VisitorsDetails from './pages/VisitorsDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<VisitorsDetails />} />
    
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    <ToastContainer />
    </>
  );
}

function NotFound() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  }, [navigate]);
  return null;
}

export default App;
