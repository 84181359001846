import React, { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import camaraImg from '../assets/images/camera.png';
import imageCompression from 'browser-image-compression';

const VisitorsPicture = ({ onImageCapture }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);

  const webcamRef = useRef(null);
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
  
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([ab], { type: mimeString });
  };
  
  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  
    try {
      const blobImage = dataURItoBlob(imageSrc);
      const compressedFile = await compressImage(blobImage);
      onImageCapture(compressedFile);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  }, [webcamRef, onImageCapture]);
  
  const compressImage = async (imageSrc) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(imageSrc, options);
    return compressedFile;
  };

  const retake = () => {
    setImgSrc(null);
  };

  const openWebcam = () => {
    setShowWebcam(true);
  };

  return (
    <>
      <div className="camaew_container">
        <h1>Visitor's Picture</h1>
        {!showWebcam && !imgSrc && (
          <div className="camaranew_style_open">
            <img className="open_images" src={camaraImg} alt="icon" />
          </div>
        )}
        {imgSrc && (
          <div className="camaranew_style">
            <img src={imgSrc} alt="webcam" />
          </div>
        )}
        {showWebcam && !imgSrc && (
          <div className="camaranew_style">
            <Webcam height={290} width={300} ref={webcamRef} />
          </div>
        )}

        <div className="btncontainer">
          {imgSrc && (
            <button type="button" onClick={retake}>
              Retake photo
            </button>
          )}

          {!imgSrc && showWebcam && (
            <button type="button" onClick={capture}>
              Capture photo
            </button>
          )}

          {!showWebcam && !imgSrc && (
            <button type="button" onClick={openWebcam}>
              Open Camera
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default VisitorsPicture;
