export const proposeofvisit = [
    { value: "Book Spaces", label: "Book Spaces" },
    { value: "Meeting", label: "Meeting" },
    { value: "Interview", label: "Interview" },
    { value: "Other", label: "Other" },
  ];
 export  const bookspaces = [
    { value: "Private Cabin", label: "Private Cabin" },
    { value: "Day Passes", label: "Day Passes" },
    { value: "Open Seats", label: "Open Seats" },
  ];
 export const numberofvisiters = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
 export const numberofseats = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: "More than 10", label: "More than 10" },
  ];
  